<template>
  <page-container>
    <div class="bg-white p-15">
      <div class="flex justify-around align-center mb-30 mt-15">
        <div class="bg-red white w-150 h-60 flex flex-column justify-around align-center">
          红色预警
          <count-to
            :end-val="totals.red"
            :duration="2000"
          />
        </div>
        <div class="bg-yellow white w-150 h-60 flex flex-column justify-around align-center">
          黄色预警
          <count-to
            :end-val="totals.yellow"
            :duration="2000"
          />
        </div>
        <div class="bg-blue white w-150 h-60 flex flex-column justify-around align-center">
          预警企业总数
          <count-to
            :end-val="totals.comp"
            :duration="2000"
          />
        </div>
      </div>

      <div class="flex align-center mb-15">
        <span class="fs-12">所属区域：</span>
        <el-cascader
          ref="myCascader"
          v-model="selectedOptions"
          clearable
          placeholder="请选择区域"
          :options="regionOptions"
          :props="regionProps"
          @change="handleItemChange"
          filterable>
        </el-cascader>
        <span class="fs-12">所属企业：</span>
        <el-select
          v-model="searchForm.compIdSelect"
          placeholder="请选择所属企业"
          filterable
          clearable
          style="width: 200px"
        >
          <el-option
            v-for="item in companyList"
            :key="item.compId"
            :label="item.compName"
            :value="item.compId"
          >
          </el-option>
        </el-select>
        <span class="fs-12">预警类型：</span>
        <el-select
          v-model="searchForm.warnLevel"
          class="w-150"
        >
          <el-option
            v-for="item in ['红色预警','黄色预警']"
            :key="item"
            :label="item"
            :value="item"
            placeholder="全部"
          />
        </el-select>
        <el-button
          type="primary"
          :loading="loading"
          class="ml-20"
          @click="onSearch"
        >
          搜 索
        </el-button>
        <el-button
          :loading="loading"
          @click="onReset"
        >
          重 置
        </el-button>
      </div>
      <resize-wrap class="flex-1">
        <BaseTable
          id="qualityWarn"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :edit-config="{trigger: 'click', mode: 'cell'}"
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :data="tableData"
        >
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column
            field="provinName"
            title="所属区域"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
            :formatter="
              ({ celllValue, row, column }) => {
                if (row.cityName != '直辖区')
                  return row.provinName + row.cityName + row.countrName;
                else return row.provinName + row.countrName;
              }
            "
          />
          <vxe-table-column
            field="compName"
            title="企业名称"
            show-overflow="title"
            show-header-overflow="title"
            min-width="60"
          />
          <vxe-table-column
            field="project"
            title="预警项目"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="reason"
            title="预警原因"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="warnLevel"
            title="预警级别 "
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column field="handle" title="处置方式" min-width="60" show-overflow="title"
                            show-header-overflow="title"  :formatter="formatterHandleTypeStr"/>
          <vxe-column field="rectify" title="整改方式" :edit-render="{}">
            <template #default="{ row }">
              <span>{{ formatterRecityStr(row.rectify) }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select v-model="row.rectify" transfer>
                <vxe-option v-for="item in dealTypeList" :key="item.dictValue" :value="item.dictValue" :label="item.dictName"></vxe-option>
              </vxe-select>
              <el-button type="primary" @click="handlerSubmit(row)" v-auth="'质量预警_整改记录保存'"  title="保存">保存</el-button>
            </template>
          </vxe-column>
          <!--      <vxe-table-column title="操作" min-width="100">
          <template v-slot="{ row }">
            <el-button type="success"  @click="editAble=false;detailRow(row)"
                       v-auth="'质量预警_整改记录保存'" title="保存"/>
          </template>
        </vxe-table-column>-->
        </BaseTable>
      </resize-wrap>

      <vxe-pager
        :loading="loading"
        :current-page="tablePage.currentPage"
        :page-size="tablePage.pageSize"
        :total="tablePage.totalSize"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
    </div>
  </page-container>
</template>

<script>
import {
  getQualityWarnPage,
  getQualityWarnSum,
  gainWarnHandle,
  gainWarnRectify,
  dealWarn
} from '@/apis/qualityWarn/index'
import CountTo from 'vue-count-to'
import { gainRegi, getComps } from '@/apis/common'
export default {
  components: {
    CountTo
  },
  data () {
    return {
      companyList: [],
      regionOptions: [],
      selectedOptions: [],
      regionProps: {
        //  multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code],
                  regiDto: {
                  }
                }).then(res => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map(val => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    })
                    )
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      dealTypeList: [
        '未整改',
        '退厂处置',
        '报废处理',
        '调整生产配合比',
        '禁止出厂',
        '调整使用',
        '同批次混凝土现场实体检测',
        '建设单位按相关验收规范要求闭合处置'
      ],
      handleTypeList: [],
      warnTypes: [
        { id: 0, name: '全部', colorClass: 'green' },
        { id: 1, name: '产能不足', colorClass: 'red' },
        { id: 2, name: '正常', colorClass: 'green' },
        { id: 3, name: '产能过剩', colorClass: 'yellow' }
      ],
      searchForm: {
        warnLevel: '',
        compIdSelect: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      allAlign: 'center',
      totals: {
        red: 0,
        yellow: 0,
        comp: 0
      },
      loading: false,
      editData: null,
      detailVisible: false,
      sortVisible: false
    }
  },
  created () {
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then(res => {
      if (res.code == 200) {
        this.regionOptions = res.data.map(val => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map(valCity => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
      }
    }).catch(val => {
      console.log(val)
    })
    getComps({}).then(res => {
      if (res.code == 200) {
        this.companyList = res.data.rows
      }
    }).catch(res => {
      console.log(res)
    })
    // 处置方式
    gainWarnHandle().then((res) => {
      this.handleTypeList = res.data
    })
    // 整改方式
    gainWarnRectify().then((res) => {
      this.dealTypeList = res.data
    })
    this.initList()
    this.initTotalData()
  },
  methods: {
    formatterHandleTypeStr ({ cellValue }) {
      if (cellValue) {
        const resArr = this.handleTypeList.filter(item => (item.dictValue == cellValue))
        const res = (resArr.length > 0 ? resArr[0].dictName : '')
        return res
      } else {
        return ''
      }
    },
    formatterRecityStr (cellValue) {
      if (cellValue) {
        const resArr = this.dealTypeList.filter(item => (item.dictValue == cellValue))
        const res = (resArr.length > 0 ? resArr[0].dictName : '')
        return res
      } else {
        return ''
      }
    },
    handleItemChange () {},
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.currentPage = 1
      this.initList()
    },
    async initList () {
      this.loading = true
      // const { totalSize, ...pageParams } = this.tablePage
      // const params = Object.assign({}, this.searchForm, pageParams)
      const res = await getQualityWarnPage({
        ...this.searchForm,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        regiCountrId: this.selectedOptions[2] ? this.selectedOptions[2] : undefined,
        regiCityId: this.selectedOptions[1] ? this.selectedOptions[1] : undefined,
        regiProvinId: this.selectedOptions[0] ? this.selectedOptions[0] : undefined
      })
      this.loading = false
      if (res.code == 200) {
        this.tablePage.totalSize = res.data.totalSize
        this.tableData = res.data.rows
      } else {
        this.$message.error(res.msg)
      }
    },
    async initTotalData () {
      const res = await getQualityWarnSum({})
      if (res.code == 200) {
        this.totals = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },
    handlerSubmit (row) {
      console.log(9999)
      dealWarn({
        id: row.warnId,
        rectify: row.rectify
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success('保存成功！')
          this.onSearch()
          this.initTotalData()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
